/// A list of social media icons that link to the company's social media pages
import React from 'react';
import LinkedIn from '../Icons/LinkedIn';
import Website from '../Icons/Website';

interface Props {
  name: string;
  linkedIn?: string;
  website?: string;
  onepageId?: string;
  crunchbaseURL?: string | null;
}

export default function SocialMediaIcons(props: Props) {
  return (
    <div className="flex ">
      <button
        className="p-2 -ml-2 rounded-lg hover:bg-stone-200"
        title="LinkedIn"
        onClick={
          props.linkedIn
            ? () => window.open(props.linkedIn, '_blank')
            : () =>
                window.open(
                  'https://www.linkedin.com/search/results/companies/?keywords=' +
                    props.name,
                  '_blank',
                )
        }
      >
        <LinkedIn />
      </button>
      <button
        className="p-2  rounded-lg hover:bg-stone-200"
        title="Website"
        onClick={() =>
          window.open(
            props.website
              ? /// ensure that the website URL is valid
                props.website.includes('http') || props.website.includes('www')
                ? props.website
                : 'https://' + props.website
              : 'https://www.google.com/search?q=' + props.name,
            '_blank',
          )
        }
      >
        <Website />
      </button>
      <button
        className="p-2  rounded-lg hover:bg-stone-200"
        onClick={() =>
          window.open(
            props.onepageId === props.name
              ? `https://app.onepagecrm.com/search/?search=${props.name}` /// just managing messy data. Can be removed once data is clean
              : props.onepageId
              ? `https://app.onepagecrm.com/show_company/${props.onepageId}`
              : `https://app.onepagecrm.com/search/?search=${props.name}`,
            '_blank',
          )
        }
      >
        <img
          src="https://media.licdn.com/dms/image/C4D0BAQHTXnuwUHH1Dg/company-logo_200_200/0/1638453513043/onepage_crm_logo?e=2147483647&v=beta&t=mUB2KHvZoPFOGxEwM9pZFPB84h3ge1Q45TSygJfqHQE"
          height={16}
          width={16}
          alt="OnePageCRM"
          title="OnePageCRM"
        />
      </button>
      {props?.crunchbaseURL && (
        <button
          className="p-2  rounded-lg hover:bg-stone-200"
          onClick={() => window.open(props?.crunchbaseURL || '', '_blank')}
        >
          <img
            src="https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/unj63uuxb8ooxctihr1w"
            height={16}
            width={16}
            alt="Crunchbase"
            title="Crunchbase"
          />
        </button>
      )}
    </div>
  );
}
