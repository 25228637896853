import React from 'react';
import { FinProductContext, section } from '../../contexts/FinProductContext';
import CaretIcon from '../Icons/CaretIcon';
import SectionQuestion from './SectionQuestion';

interface Props {
  section: section;
}
export default function ToggleableApplicationSection(props: Props) {
  const { selectedApplicationId, applications } =
    React.useContext(FinProductContext);
  const { section } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (selectedApplicationId) {
      // status is help on the applications endpoint not the application detail endpoint
      const application = applications.find(
        app => app.id === selectedApplicationId,
      );
      if (application) {
        // TODO: use the order on the sections as well as their status to determine if they should be open
        // ie if the section is the current stage and has questions, open it
        // if the section comes before the current stage and has questions, open it
        if (section.stage === application.status && section.questions.length) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    }
  }, [selectedApplicationId, applications, section.type]);

  return (
    <div
      key={section.id + selectedApplicationId}
      className={
        ' m-1 sm:m-2 rounded-xl bg-white border border-1 border-solid border-stone-200 shadow overflow-hidden transition-all duration-300' +
        (!section.questions.length ? ' opacity-40' : '')
      }
    >
      {/* toggleable component */}
      <button
        onClick={() => setOpen(!open)}
        disabled={!section.questions.length}
        className={
          '  p-2 w-full text-start bg-stone-50 flex justify-between items-center  '
        }
      >
        <div className="text-lg font-bold pl-2">{section.title}</div>
        {/* icon */}
        <div className="pr-2">
          <CaretIcon isOpen={open} />
        </div>
      </button>
      {open ? (
        <div className="py-1">
          {/* section subtitle */}
          {section.subtitle?.length && (
            <div className="text-sm p-2">{section.subtitle}</div>
          )}

          <SectionQuestion section={section} />
        </div>
      ) : null}
    </div>
  );
}
