import React, { useContext } from 'react';
import NavigatorHeader from '../components/Navigator/NavigatorHeader';
import { ConfigContext } from '../contexts/ConfigContext';

export default function TempSFLabs() {
  const { config } = useContext(ConfigContext);
  return (
    <div>
      <NavigatorHeader />
      {typeof config.features?.navigator?.small_foundation_labs === 'string' ? (
        <iframe
          src={config.features.navigator.small_foundation_labs}
          width="100%"
          height={window.innerHeight - 64}
          title="SFTempFDG"
        />
      ) : (
        <h1>Small Foundation Labs is not enabled for this partner</h1>
      )}
    </div>
  );
}
