import React, { useContext } from 'react';
import {
  FinProductContext,
  FinProductContextProps,
} from '../../contexts/FinProductContext';

interface Props {
  match: FinProductContextProps['applications'][0];
}

export default function GrantProgressCard(props: Props) {
  const { selectedApplicationId, setSelectedApplicationId } =
    React.useContext(FinProductContext);
  // extract data from grant
  const name = props.match.company.name;

  // state for error of logo loading
  const [logoError, setLogoError] = React.useState(false);

  // onclick set as selected grant
  const onClick = () => {
    setSelectedApplicationId(props.match.id);
  };

  return (
    <button
      className={'group w-full hover:scale-[0.99]'}
      onClick={onClick}
      key={props.match.id}
    >
      <div
        className={
          '  group-hover:bg-stone-200  shadow-lg px-4 py-2 text-left overflow-hidden ' +
          (selectedApplicationId === props.match.id
            ? ' bg-stone-200 '
            : ' bg-white ')
        }
      >
        <div className="flex items-center justify-start  whitespace-nowrap overflow-hidden text-ellipsis text-sm">
          {props.match.company.logo && !logoError ? (
            <img
              src={props.match.company.logo}
              alt={name}
              className="h-4  rounded-full  object-cover drop-shadow"
              onError={() => {
                console.log('error', props.match.company.logo);
                setLogoError(true);
              }}
            />
          ) : null}
          <div
            className={
              'pl-2' +
              (selectedApplicationId === props.match.id ? ' font-bold ' : '')
            }
          >
            {name}
          </div>
        </div>
      </div>
    </button>
  );
}
