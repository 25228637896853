import React, { useEffect } from 'react';
import { VulaPartnerProductsAPI } from '../../../api/products';
import { FinProductContext } from '../../../contexts/FinProductContext';
import { useAuth0 } from '@auth0/auth0-react';
import UploadedDocumentCard from '../Tabs/UploadedDocumentCard';

export default function PreviouslyUploadedDocs() {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedApplicationId: application_id, selectedProductApplication } =
    React.useContext(FinProductContext);
  const [data, setData] = React.useState<
    {
      id: string;
      label: string;
      content: string;
      filename: string;
    }[]
  >([]);

  // on load get the data
  useEffect(() => {
    // Fetch data from API
    (async () => {
      const token = await getAccessTokenSilently();
      const api = new VulaPartnerProductsAPI({ token });

      if (!selectedProductApplication?.id || !application_id) return;
      const response = await api.getAttachments(
        selectedProductApplication?.id,
        application_id,
      );
      setData(response.data);
    })();
  }, []);

  return (
    <div className="px-2">
      {data?.map(upload => {
        return <UploadedDocumentCard key={upload.id} upload={upload} />;
      })}
    </div>
  );
}
