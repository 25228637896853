import React, { useContext } from 'react';
import { capitalizeFirstLetter } from '../../../methods/UIHelpers';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}

export default function TableQuestion(props: Props) {
  const { addToPartnerCorpus, selectedApplicationId } =
    useContext(FinProductContext);
  const { question } = props;
  return (
    <tr className="rounded-md overflow-hidden" key={question.id}>
      <td className="rounded-l-xl bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 w-fit whitespace-nowrap">
        <MarkdownText content={capitalizeFirstLetter(question.question)} />
      </td>
      <td
        className="border border-0 border-b-2 px-2 border border-solid border-stone-200 w-full my-auto"
        contentEditable={question.corpus_type.includes('relationship')}
        onBlur={e => {
          if (
            question.corpus_type.includes('relationship') &&
            selectedApplicationId
          ) {
            addToPartnerCorpus({
              question_id: question.id,
              content: e.currentTarget.innerText,
              relationship_id: selectedApplicationId,
            });
          }
        }}
      >
        {question.answer}
      </td>
    </tr>
  );
}
