import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaPartnersAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  async getPartnerConfig() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(`${apiUrl}/partners/config`, config);
  }

  async getPresignedUrl(type: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/attachment/get_presigned_url?`,
      { type },
      config,
    );
  }

  async addToPartnerCorpus({
    question_id,
    content,
    relationship_id,
  }: {
    question_id?: string;
    content?: string;
    relationship_id?: string;
  }) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/partners/corpus`,
      {
        question_id,
        content,
        relationship_id,
      },
      config,
    );
  }
}
