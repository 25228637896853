export const routes = {
  landing: '/',
  onlogin: '/onlogin',
  launch: '/launch',
  home: '/home',
  create_new_programme: '/create_new_programme',
  product: '/product/*',
  search: '/search',
  temp_sf_labs: '/temp_sf_labs',
  application_overview: '/application_overview',
  application_management: '/application_management',
  mock_programme_dashboard: '/mock_programme_dashboard',
  mock_ic_view: '/mock_ic_view',
  mock_disbursement_view: '/mock_disbursement_view',
};
