import React from 'react';

interface Props {
  upload: {
    content: string;
    filename: string;
  };
}
export default function UploadedDocumentCard({ upload }: Props) {
  return (
    <div className="flex flex-row items-center rounded-xl bg-stone-50 shadow p-2 my-1 hover:scale-[0.99]">
      <a href={upload.content} target="_blank" rel="noreferrer">
        <div className="flex items-center justify-start">
          <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/file-check-alt.svg"
            className="h-8 w-8 mr-2"
          />
          <p className="text-xs">{upload.filename}</p>
        </div>
      </a>
    </div>
  );
}
