import React from 'react';
import InboxDivider from './InboxDivider';

import InboxReaderHeader from './InboxReaderHeader';
import InboxReader from './InboxReader';
import {
  applicationsSummary,
  FinProductContext,
} from '../../contexts/FinProductContext';

import DocAnalysis from './DocAnalysis/DocAnalysis';

export default function ProductInbox() {
  const {
    applications: matches,
    uploadReviewId,
    selectedApplicationId,
  } = React.useContext(FinProductContext);

  const [showInboxListMobile, setShowInboxListMobile] = React.useState(false);
  const [types, setTypes] = React.useState<string[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredMatches, setFilteredMatches] = React.useState(matches);

  React.useEffect(() => {
    if (searchTerm && matches) {
      const filteredMatches = matches.filter(match =>
        match.company.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredMatches(filteredMatches);

      // set the types to the filtered matches
      setTypes(seperateByType(filteredMatches));
    } else if (matches) {
      setFilteredMatches(matches);

      // set the types to the filtered matches
      setTypes(seperateByType(matches));
    }
  }, [matches, searchTerm]);

  const seperateByType = (matches: applicationsSummary[]) => {
    const permanentTypes = ['submitted', 'archived', 'awaiting', 'rejected'];
    const newTypes = matches.map(match => match.status);
    const dedupedTypes = [...new Set([...newTypes, ...permanentTypes])];
    return dedupedTypes;
  };

  // when selectedApplicationId changes, we can assume that the user has clicked on a new application and hence we can hide the inbox list
  React.useEffect(() => {
    setShowInboxListMobile(false);
  }, [selectedApplicationId]);

  return (
    <div className="max-w-full m-auto  break-words	">
      <div className="flex flex-row w-full h-[95vh] sm:h-[93vh] rounded-xl shadow-2xl bg-stone-100 overflow-hidden">
        {/* inbox cards */}
        <div
          className={
            (showInboxListMobile ? '' : 'hidden') +
            ' sm:flex relative flex flex-col justify-between w-full sm:max-w-[250px] min-h-[90vh] border border-r-1 border-y-0 border-l-0 border-gray-100 border-solid rounded-l-xl transition-all duration-300'
          }
        >
          <div className="flex-grow overflow-auto no-scrollbar ">
            {types.map(type => (
              <InboxDivider
                key={`${types.length}-${type}-${matches?.length}-${searchTerm}`}
                type={type}
                companyMatches={
                  filteredMatches?.filter(match => match.status === type) ?? []
                }
                isSearching={searchTerm.length > 0}
              />
            ))}
          </div>

          {/* Search input always at the bottom */}
          <div className="flex-shrink-0 bg-white w-full flex flex-row justify-between items-center p-2">
            <input
              type="text"
              placeholder="Filter by company name"
              className="w-full p-2 border border-gray-100 border-solid rounded-xl text-sm"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full ">
          <InboxReaderHeader
            showInboxListMobile={() =>
              setShowInboxListMobile(!showInboxListMobile)
            }
          />
          <div className=" overflow-scroll no-scrollbar ">
            {uploadReviewId ? <DocAnalysis /> : <InboxReader />}
          </div>
        </div>
      </div>
    </div>
  );
}
