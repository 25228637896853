import React from 'react';
import CompanyProcessStatus from './CompanyProcessStatus';

import { FinProductContext } from '../../contexts/FinProductContext';
import TabsForReviewingCompany from './TabsForReviewingCompany';
import ApplicationQuestions from './ApplicationQuestions';
import NextStageOptions from './NextStageOptions/NextStageOptions';
import PrivateNotes from './PrivateNotes';
import { ConfigContext } from '../../contexts/ConfigContext';

export default function InboxReader() {
  const { config } = React.useContext(ConfigContext);
  const { selectedProductApplication, isLoadingApplication } =
    React.useContext(FinProductContext);
  const { company } = selectedProductApplication ?? {};

  return (
    <div
      className="bg-white rounded flex flex-col sm:flex-row  "
      style={{
        maxWidth: window.innerWidth > 600 ? window.innerWidth - 250 : '100%',
      }}
    >
      <div
        className={
          '  h-[89vh] sm:h-[85vh] overflow-scroll no-scrollbar w-full' +
          (config?.features?.rightHandSideTab?.visible
            ? ' sm:w-[66%]'
            : ' w-full')
        }
      >
        {/* Header */}
        <div className="px-2 pt-2 text-sm text-center items-center flex ">
          {isLoadingApplication ? (
            // a animating bg loading state
            <div
              className={
                'rounded-full text-stone-50 px-6 w-[150px] h-[25px] mx-4 animate-pulse  bg-stone-200 opacity-20'
              }
            ></div>
          ) : (
            <div className="rounded-full bg-stone-300 text-stone-50 px-6 max-h-[25px]">
              {selectedProductApplication?.name}
            </div>
          )}
        </div>
        <div className="px-2 py-2 flex flex-row justify-between items-center">
          <div className="text-left ">
            {isLoadingApplication ? (
              <div className="h-[60px] w-[100px] animate-pulse  bg-stone-200 opacity-20 m-4 rounded"></div>
            ) : company?.logo.length ? (
              <div className="m-2 w-fit h-fit rounded ring ring-1 ring-stone-300 ">
                <img
                  key={company?.logo}
                  src={company?.logo}
                  className="w-full h-full p-2 rounded drop-shadow-[0_0px_1px_rgba(0,0,0,.4)] object-contain hover:bg-stone-500 transition-all duration-400"
                  style={{
                    width: 'auto',
                    maxWidth: '250px',
                    height: 'auto',
                    maxHeight: '100px',
                  }}
                  onLoad={e => {
                    // if the width and height are the same, remove the drop shadow
                    if (e.currentTarget.width === e.currentTarget.height) {
                      e.currentTarget.classList.remove(
                        'drop-shadow-[0_0px_1px_rgba(0,0,0,.4)]',
                      );
                    }
                  }}
                  onError={e => {
                    e.currentTarget.hidden = true;
                  }}
                />
              </div>
            ) : (
              <div className="mb-4"></div>
            )}
            {/* <div className="font-light text-sm">{company_location}</div> */}
            {isLoadingApplication ? (
              <div className="h-[20px] w-[100px] animate-pulse  bg-stone-200 opacity-20 m-4 rounded"></div>
            ) : (
              <div className="font-extrabold text-xl ">{company?.name}</div>
            )}
          </div>
          <CompanyProcessStatus />
        </div>

        {/* partner private notes  - a text area for private notes*/}
        {isLoadingApplication ? (
          <div className=" h-[100px] animate-pulse  bg-stone-200 opacity-20 m-2 rounded"></div>
        ) : (
          <PrivateNotes />
        )}

        {/* divider line */}
        <div className="border border-solid border-stone-100 mt-4" />

        {/* Application questions */}
        <div className="text-left">
          {isLoadingApplication ? (
            [1, 2, 3, 4, 5].map(i => (
              <div
                key={i}
                className="h-[50px] animate-pulse  bg-stone-200 opacity-20 m-1 sm:m-2 rounded"
              ></div>
            ))
          ) : (
            <ApplicationQuestions />
          )}
        </div>

        {/* Move the application to the next stage? */}
        {config?.features?.demoNextSteps?.visible && <NextStageOptions />}

        {config?.features?.rightHandSideTab?.visible && (
          <div className="sm:hidden block">
            <TabsForReviewingCompany />
          </div>
        )}
      </div>
      {config?.features?.rightHandSideTab?.visible && (
        <div className="sm:w-[30%] hidden sm:block">
          <TabsForReviewingCompany />
        </div>
      )}
    </div>
  );
}
