import React, { useContext } from 'react';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}

export default function LongQuestion(props: Props) {
  const { addToPartnerCorpus, selectedApplicationId } =
    useContext(FinProductContext);
  const [editMode, setEditMode] = React.useState(false);
  const [heightOfDiv, setHeightOfDiv] = React.useState(0);
  const { question } = props;
  const [answer, setAnswer] = React.useState(question.answer);

  const saveAnswer = async () => {
    if (question.answer !== answer) {
      await addToPartnerCorpus({
        question_id: question.id,
        content: answer || undefined,
        relationship_id: selectedApplicationId || undefined,
      });
    }
  };

  return (
    <div>
      {/* question */}
      <div className="pt-1 px-1 text-sm">
        <MarkdownText content={question.question} />
      </div>

      {/* answer */}
      {editMode ? (
        <textarea
          className="text-left relative bg-stone-100 border border-1 border-solid border-stone-300 text-stone-900 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-full"
          style={{ height: heightOfDiv }}
          defaultValue={question.answer || ''}
          onChange={e => {
            setAnswer(e.currentTarget.value);
          }}
          onBlur={async e => {
            setEditMode(!editMode);
            await saveAnswer();
          }}
          autoFocus
        />
      ) : // if relationship the user should be able to edit the text, otherwise do not allow editing
      question.corpus_type.includes('relationship') ? (
        <button
          className="text-left relative bg-stone-100 border border-1 border-solid border-stone-300 text-stone-900 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          onClick={() => {
            setEditMode(!editMode);
          }}
          // Store the height of the div so that the textarea can be the same size
          ref={div => {
            if (div) {
              setHeightOfDiv(div.clientHeight);
            }
          }}
        >
          {question.answer?.length ? (
            <MarkdownText content={answer || ''} />
          ) : (
            'Click to add answer'
          )}
        </button>
      ) : (
        <div className="text-left relative bg-stone-100 border border-1 border-solid border-stone-300 text-stone-900 rounded-lg block w-full p-2.5">
          {question.answer?.length ? (
            <MarkdownText content={question.answer || ''} />
          ) : null}
        </div>
      )}
    </div>
  );
}
