import React from 'react';
import { FinProductContext } from '../../contexts/FinProductContext';

export default function PrivateNotes() {
  const { selectedProductApplication, upsertPartnerNotes } =
    React.useContext(FinProductContext);

  // update default value to selectedProductApplication.partner_notes when selectedProductApplication changes
  React.useEffect(() => {
    if (selectedProductApplication?.id.toString()) {
      const textArea = document.getElementById(
        selectedProductApplication?.id.toString(),
      ) as HTMLTextAreaElement;
      if (textArea) {
        textArea.defaultValue = selectedProductApplication?.partner_notes || '';
      }
    }
  }, [selectedProductApplication]);

  return (
    <div className="flex flex-col mx-2 mt-4 relative">
      <label className="text-xs font-bold absolute -top-2 bg-white px-4 left-4">
        Partner private notes
      </label>
      <textarea
        id={selectedProductApplication?.id.toString()}
        className="border border-solid border-stone-100 rounded p-3"
        placeholder="Add private notes here"
        onBlur={e => {
          // only save if the text is different
          if (selectedProductApplication?.partner_notes !== e.target.value) {
            upsertPartnerNotes(e.target.value);
          }
        }}
        defaultValue={selectedProductApplication?.partner_notes || ''}
      />
    </div>
  );
}
