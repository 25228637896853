import React from 'react';

interface Props {
  color?: 'white' | 'black';
}
export default function HomeIcon(props: Props) {
  return (
    <svg
      role="graphics-symbol"
      className=""
      style={{
        width: '16px',
        height: '16px',
        display: 'block',
        fill:
          props.color === 'white'
            ? 'rgba(255, 255, 255, 0.45)'
            : 'rgba(55, 53, 47, 0.45)',
        flexShrink: 0,
      }}
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M1 6V15H6V11C6 9.89543 6.89543 9 8 9C9.10457 9 10 9.89543 10 11V15H15V6L8 0L1 6Z"
          fill="#000000"
        />{' '}
      </g>
    </svg>
  );
}
