import React from 'react';
import ArrowIcon from '../Icons/ArrowIcon';
import { FinProductContext } from '../../contexts/FinProductContext';
import SlantedHamburger from '../Icons/SlantedHamburger';

interface Props {
  showInboxListMobile: () => void;
}

export default function InboxReaderHeader(props: Props) {
  const {
    applications: matches,
    selectedApplicationId,
    setSelectedApplicationId: setSelectedMatch,
    uploadReviewId,
    setUploadReviewId,
  } = React.useContext(FinProductContext);

  const moveToNextMatch = (increase: 1 | -1) => {
    // on click set the selected match as the next match or previous match
    if (selectedApplicationId === null) return;

    const selectedMatchIndex = matches.findIndex(
      match => match.id === selectedApplicationId,
    );

    const nextMatchIndex = selectedMatchIndex + increase;

    // if the next is outside of the length of the array, loop through
    if (nextMatchIndex < 0) {
      setSelectedMatch(matches[matches.length - 1].id);
      return;
    }
    if (nextMatchIndex > matches.length - 1) {
      setSelectedMatch(matches[0].id);
      return;
    }
    const nextMatch = matches[nextMatchIndex];
    setSelectedMatch(nextMatch.id);
  };

  if (uploadReviewId) {
    return (
      <div className={'h-14 w-full flex justify-between  bg-white '}>
        <button
          onClick={() => setUploadReviewId(null)}
          className={
            'm-2 rounded pl-2 flex flew-row items-center  hover:bg-gray-100'
          }
        >
          <ArrowIcon />
          <div className="px-2 font-extrabold text-sm">Back to application</div>
        </button>
      </div>
    );
  }

  return (
    <div
      className={
        'h-14 w-full flex justify-between  bg-white border-b border border-gray-100 border-solid border-1'
      }
    >
      {/* if mobile don't show previous, instead show all companies in inbox */}
      <button
        onClick={() => props.showInboxListMobile()}
        className={
          'flex sm:hidden m-2 rounded pl-2 flex flew-row items-center  hover:bg-gray-100'
        }
      >
        <SlantedHamburger />
        <div className="px-2 font-extrabold text-sm">Inbox</div>
      </button>

      {/* previous button */}
      <button
        onClick={() => moveToNextMatch(-1)}
        className={
          'hidden sm:flex m-2 rounded pr-2 flex flew-row items-center  hover:bg-gray-100'
        }
      >
        <div>
          <ArrowIcon pointingDirection="left" />
        </div>
        <div className="px-2 font-extrabold text-sm flex items-center">
          <span>Previous</span>
          <span className="hidden sm:block pl-1 ">Company</span>
        </div>
      </button>

      {/* next button  */}
      <button
        onClick={() => moveToNextMatch(1)}
        className={
          ' m-2 rounded pr-2 flex flew-row items-center  hover:bg-gray-100'
        }
      >
        <div className="px-2 font-extrabold text-sm flex items-center">
          <span>Next</span>
          <span className="hidden sm:block pl-1">Company</span>
        </div>
        <ArrowIcon pointingDirection={'right'} />
      </button>
    </div>
  );
}
