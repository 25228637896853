import React from 'react';
import DetailWindow from '../components/CompanyNavigatorView/DetailWindow';
import TableOfCompanies from '../components/CompanyNavigatorView/TableOfCompanies';
import { CompanyData } from '../data/sf';
import NavigatorHeader from '../components/Navigator/NavigatorHeader';

export default function Search() {
  const [searchText, setSearchText] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState<
    CompanyData | undefined
  >();

  // on load get the search query from the search param in the url (search?s=)
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('s');
    if (searchQuery) {
      setSearchText(searchQuery);
    }

    // remove the search query from the url once consumed
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  return (
    <div>
      {/* search bar */}
      <NavigatorHeader searchText={searchText} setSearchText={setSearchText} />

      {/* table */}
      <div className="flex h-full ">
        <div
          className={
            ' sm:px-4 ' +
            (selectedRow ? ' hidden sm:block sm:w-2/3 ' : ' w-full')
          }
        >
          <TableOfCompanies
            onRowSelect={setSelectedRow}
            searchTerm={searchText}
            selectedRowId={selectedRow?.id}
          />
        </div>

        {selectedRow ? (
          <div className="sm:py-2 sm:pr-4 w-full sm:w-1/3 min-h-full ">
            <DetailWindow
              key={selectedRow?.id}
              selectedRowId={selectedRow?.id}
              closeModal={() => setSelectedRow(undefined)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
