import React from 'react';
import FlagIcon from '../Icons/FlagIcon';
import { CompanyData } from '../../data/sf';
import { sendNotification } from '../../api/monitoring';

interface Props {
  selectedRow: CompanyData | undefined;
}

export default function DetailWindowFlag(props: Props) {
  const [showUserInput, setShowUserInput] = React.useState(false);
  const [flagNotes, setFlagNotes] = React.useState('');
  const reportFlag = async () => {
    // send flag to server using monitoring service
    await sendNotification(
      `Flagged company: ${props.selectedRow?.name} with notes: ${flagNotes}`,
      'Company flagged in partners portal',
      'a',
    );
  };

  if (!props.selectedRow) return null;

  if (showUserInput) {
    return (
      <div className="flex w-full flex-row items-center">
        <input
          placeholder="Any notes?"
          autoFocus
          className="w-full bg-stone-50 ml-8 rounded-lg p-1 text-stone-900"
          value={flagNotes}
          onChange={e => setFlagNotes(e.target.value)}
        />
        <button
          className="bg-stone-600 text-white rounded-lg p-1  px-3 ml-2 w-fit"
          onClick={async () => {
            await reportFlag();
            setShowUserInput(false);
          }}
        >
          Flag
        </button>
        <button
          className="text-stone-100 ml-2"
          onClick={() => setShowUserInput(false)}
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <button
      className="hover:bg-stone-600 transition-all duration-500 rounded-lg p-1 w-fit"
      onClick={() => setShowUserInput(true)}
    >
      <FlagIcon color="white" />
    </button>
  );
}
