import React from 'react';
import DocumentRender from './DocumentRender';
import {
  FinProductContext,
  question,
} from '../../../contexts/FinProductContext';
import {
  convertToReadableNumber,
  takeLetterUnitsAndConvertToNumber,
} from '../../../methods/UIHelpers';
import Spinner from '../../utils/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaPartnerDocumentsAPI } from '../../../api/documents';
import { AnalysisData } from './BalanceSheetAnalysis';

interface Props {
  selectedQuestion: question | null;
}

export default function GenericAnalysis(props: Props) {
  const { selectedQuestion } = props;
  const { selectedProductApplication, uploadReviewId } =
    React.useContext(FinProductContext);
  const { getAccessTokenSilently } = useAuth0();

  const [results, setResults] = React.useState<AnalysisData[]>([]);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // on load get the data from the api
  React.useEffect(() => {
    // when an new url is gathered, call the api for analysis
    if (selectedQuestion?.answer) {
      (async () => {
        try {
          setIsLoading(true);
          if (!selectedProductApplication?.company.id) {
            return null;
          }
          const token = await getAccessTokenSilently();
          const api = new VulaPartnerDocumentsAPI({ token });
          const response = await api.getDocumentsAnalysis(
            selectedProductApplication?.company.id,
            selectedQuestion.corpus_label,
          );

          if (response.data) {
            setResults(response.data);
          }

          setIsLoading(false);
        } catch (err: any) {
          console.log(err);
          // if 404, then the document has not been analysed - just show the document
          if (err.response.status === 404) {
            setShowError(false);
          }
          setIsLoading(false);
        }
      })();
    }
  }, []);

  const updateResults = (id: string, value: number | string) => {
    // save to db
    (async () => {
      try {
        const company_id = selectedProductApplication?.company.id;

        if (!company_id || !value) {
          console.log('no company id ', company_id, value);
          return null;
        }
        const token = await getAccessTokenSilently();
        const api = new VulaPartnerDocumentsAPI({ token });
        await api.updateDocumentAnalysis(id, value);
      } catch (err) {
        console.log(err);
      }
    })();

    // update the results array
    const newResults = results.map(result => {
      if (result.id === id) {
        result.content = value.toString();
      }
      return result;
    });
    setResults(newResults);
  };

  const updateNote = (value: string) => {
    if (!uploadReviewId) return;
    // save to db
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const api = new VulaPartnerDocumentsAPI({ token });

        await api.createDocumentNote(uploadReviewId, value);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const splitAndCapitalise = (str: string) => {
    // if there is an & in the string, split it and capitalise each word
    if (str.includes('&')) {
      const split = str.split('&');
      const capitalised = split.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      str = capitalised.join('&');
    }
    // takes underscores and capitalises the first letter of each word
    const split = str.split('_');
    const capitalised = split.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalised.join(' ');
  };

  const getVariableFromLabel = (label: string) => {
    // find the variable from the results array
    const variable = results.find(result => result.label === label);
    if (!variable) return null;
    return variable;
  };

  return (
    <div className="flex bg-stone-50 p-4 rounded-xl">
      {showError ? (
        <div className="text-5xl font-bold mt-20">
          Error reading your pdf. Please try again.
        </div>
      ) : isLoading ? (
        <div className="flex items-center mt-20">
          <Spinner />
          <div className="text-5xl font-bold ">
            Vula is analysing the document...
          </div>
        </div>
      ) : results.length ? (
        <div className="w-1/2 flex justify-between overflow-scroll no-scrollbar h-[80vh]">
          {/* i dont like using 80vh but we need to define some window height and I am being lazy by not querying the browser size - sorry */}
          <div className=" p-4">
            <div className="text-5xl font-bold ">
              {splitAndCapitalise(selectedQuestion?.corpus_label || '')}
            </div>
            <div className="text font-bold py-4">
              The document has been pre-analysed by Vula. <br />
              Please review the results below.
            </div>
            {/* notes */}
            <div className="py-4 ">
              <textarea
                className="w-full h-24 text-sm p-4 border border-solid border-stone-200 bg-white rounded-lg"
                placeholder="Add a comment here..."
                defaultValue={getVariableFromLabel('investor_notes')?.content}
                onBlur={e => {
                  updateNote(e.currentTarget.value);
                }}
              ></textarea>
            </div>
            {/* tables */}
            <table className="table-auto text-left text-sm pt-12">
              <tbody>
                {/* for each key of the results object */}
                {results.map((item, index) => {
                  // We dont want to show the document currency or the investor notes as we display it above
                  if (item.label === 'investor_notes') {
                    return null;
                  }
                  return (
                    <>
                      <tr key={item.id}>
                        <td className=" bg-stone-100 border-2 border border-solid border-stone-200 px-4 py-2 w-[40%]] font-bold">
                          {item.question}
                        </td>
                        <td className=" bg-stone-100 border-2 border border-solid border-stone-200 px-4 py-2 w-[60%] ">
                          <div
                            className="w-full"
                            onBlur={e => {
                              if (e.currentTarget.innerText === item.content)
                                return;
                              updateResults(
                                item.id,
                                item.subtype?.includes('units:')
                                  ? takeLetterUnitsAndConvertToNumber(
                                      e.currentTarget.innerText,
                                    )
                                  : e.currentTarget.innerText || item.content,
                              );
                            }}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {item?.subtype?.includes('units:')
                              ? convertToReadableNumber(item.content)
                              : item.content}
                          </div>
                        </td>
                      </tr>
                      {item.children.length > 0 && (
                        <tr
                          key={item.id + '_justification'}
                          className="bg-stone-50 border-2 border border-solid border-stone-200 px-4 py-2 text-xs w-full"
                        >
                          <td colSpan={2} className="w-full px-4 py-1">
                            {item?.author?.email
                              ? `Modified by ${item.author.email}`
                              : item.children[0].content}
                          </td>
                        </tr>
                      )}
                      {index === results.length - 1 ? null : (
                        <tr className="col-span-2">
                          <td
                            className="border-0 border-b-2 border border-solid border-stone-200 py-4"
                            colSpan={2}
                          ></td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div
        className={' h-auto  p-4 ' + (results.length ? ' w-1/2 ' : ' w-full')}
      >
        <DocumentRender answer={selectedQuestion?.answer} />
        {selectedQuestion?.answer ? (
          <div className="text-center py-1">
            <a
              href={selectedQuestion?.answer}
              target="_blank"
              rel="noreferrer"
              className="text-stone-400 text-xs hover:text-blue-500"
            >
              Having trouble? View in a new tab.
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
