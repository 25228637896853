import React from 'react';

interface Props {
  answer?: string | null | undefined; // document url
}

export default function DocumentRender(props: Props) {
  const { answer } = props;
  if (!answer) return null;
  if (answer.length === 0) return null;

  return (
    <div className=" w-full min-h-[70vh] h-full">
      {answer?.includes('.pdf') ? (
        <iframe
          src={answer || ''}
          width="100%"
          height="100%"
          frameBorder="0"
          className=""
          allowFullScreen
        ></iframe>
      ) : ['.png', '.jpg', '.jpeg'].some(el => answer?.includes(el)) ? (
        <img
          src={`${answer}`}
          alt="File preview"
          className="w-full h-auto max-w-[800px]"
        />
      ) : (
        <div className="text-2xl font-bold text-center">
          No preview available - please contact us
        </div>
      )}
    </div>
  );
}
