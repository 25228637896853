import React from 'react';

export default function SlantedHamburger() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        height="30px"
        viewBox="0 0 512 512"
        version="1.1"
      >
        <g id="Layer_2">
          <g>
            <g>
              <path d="M454.48,152.2H236.13c-7.74,0-14.02-6.28-14.02-14.02v-37.57c0-7.74,6.28-14.02,14.02-14.02h218.35     c7.74,0,14.02,6.28,14.02,14.02v37.57C468.5,145.92,462.22,152.2,454.48,152.2z" />
            </g>

            <g>
              <path d="M365.18,288.8H146.82c-7.74,0-14.02-6.28-14.02-14.02v-37.57c0-7.74,6.28-14.02,14.02-14.02h218.35     c7.74,0,14.02,6.28,14.02,14.02v37.57C379.19,282.53,372.92,288.8,365.18,288.8z" />
            </g>

            <g>
              <path d="M275.87,425.41H57.52c-7.74,0-14.02-6.28-14.02-14.02v-37.57c0-7.74,6.28-14.02,14.02-14.02h218.35     c7.74,0,14.02,6.28,14.02,14.02v37.57C289.89,419.13,283.61,425.41,275.87,425.41z" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
