import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../pages/routes';
import VulaLogo from '../utils/VulaLogo';
import LogoutButton from '../../interfaces/auth0/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import HomeIcon from '../Icons/HomeIcon';

export default function HomeNavBar() {
  const { user } = useAuth0();
  const navigate = useNavigate();
  return (
    <div className="p-2 border-b border-solid border-gray-200 flex justify-center">
      <div className="max-w-[1500px] w-full flex flex-row justify-between sm:px-32">
        {
          // if user is on home page, show logo else show back button
          window.location.pathname === routes.home ? (
            <VulaLogo />
          ) : (
            <button
              className=" flex justify-center flex-row items-center pl-4"
              onClick={() => navigate(routes.home)}
            >
              <HomeIcon />
              <div className="hidden sm:block px-2 text-lg font-extrabold ">
                Home
              </div>
            </button>
          )
        }
        <div className="flex  items-center justify-center  rounded-lg  hover:bg-stone-100">
          {/* on hover show a logout button */}
          <div className="group">
            {user?.picture ? (
              <img
                src={user.picture}
                alt="Profile"
                className="rounded-full w-8 h-8"
              />
            ) : (
              <img
                src="https://cdn.iconscout.com/icon/free/png-256/account"
                alt="Profile"
                className="rounded-full w-12 h-12"
              />
            )}
            <div className="duration-500 transition-all hidden h-0 w-0 group-hover:h-fit group-hover:w-fit group-hover:block absolute bg-white rounded-lg p-2 shadow-md">
              <LogoutButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
