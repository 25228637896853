import React from 'react';
import { question } from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  question: question;
}

export default function Title({ question }: Props) {
  return (
    <div className="pt-4 ">
      <div
        className={
          // if question is order 0 then no padding top and no line above
          question.order === 0
            ? 'px-1 text-gray-800 '
            : 'px-1 pt-4 text-gray-800 border border-x-0 border-b-0 border-t-1 border-stone-200 border-solid'
        }
      >
        <MarkdownText content={question.question} />
      </div>
    </div>
  );
}
