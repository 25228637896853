import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { VulaChatAPI } from '../../../api/chat';
import Spinner from '../../utils/Spinner';
import { FinProductContext } from '../../../contexts/FinProductContext';
import { MarkdownText } from '../../utils/MarkdownText';

interface Props {
  company_id: string;
  partner_id: string;
  defaultMessages?: string[];
  botIcon?: string;
  isLoading?: boolean;
}

export default function GenericChatInterface(props: Props) {
  const { selectedApplicationId } = useContext(FinProductContext);
  const userAskInputRef = React.useRef<HTMLTextAreaElement>(null);

  // context
  const { user, getAccessTokenSilently } = useAuth0();

  // state
  const [messages, setMessages] = React.useState<
    { text: string; askedBy: string }[]
  >([]);
  const [userAskInput, setUserAskInput] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  // update the userAskInput state when the user types
  // React.useEffect(() => {
  //   setUserAskInput(userAskInputRef.current?.value || '');
  // }, [userAskInputRef.current?.value]);

  // when the selected application changes, clear the chat
  React.useEffect(() => {
    setMessages([]);
  }, [selectedApplicationId]);

  const askVulaAboutTheCompany = async () => {
    if (userAskInputRef.current?.value.length) {
      setLoading(true);
      setMessages([]);
      // get token from auth0
      const token = await getAccessTokenSilently();
      const api = new VulaChatAPI({ token });
      // send a message to the chat bot
      await api
        .askVulaChat(
          props.company_id,
          props.partner_id,
          userAskInputRef.current?.value,
        )
        .then(data => {
          if (userAskInputRef?.current?.value) {
            userAskInputRef.current.value = '';
          }

          // clear the input
          // replace the response to the messages array
          setMessages([
            {
              text: data.data.question,
              askedBy: 'user',
            },
            {
              text: data.data.answer,
              askedBy: 'vula',
            },
          ]);
        })
        .catch(error => {
          console.error('Error:', error);
        });

      setLoading(false);
    }
  };

  const defineWriterImage = (askedBy: string) => {
    if (askedBy === 'user') {
      return user?.picture?.length
        ? user.picture
        : 'https://vula-assets.s3.eu-west-2.amazonaws.com/icons/person.svg';
    } else {
      return props.botIcon?.length ? props.botIcon : '/vula-sun.png';
    }
  };

  const renderDefaultMessages = () => {
    if (props.isLoading) {
      return [0, 0].map(box => (
        <div className="animate-pulse bg-stone-200 opacity-20 h-[75px] mb-2  rounded-lg "></div>
      ));
    }

    // if there are messages, hide the default messages
    if (messages.length || userAskInputRef.current?.value.length) {
      return null;
    }

    return props.defaultMessages?.map(message => (
      <div
        key={message}
        className=" shadow border border-solid border-1 border-stone-300 hover:bg-stone-100  m-2 rounded-xl"
      >
        <button
          className=" flex flex-row items-center justify-between w-full px-1 py-1"
          onClick={async () => {
            userAskInputRef.current!.value = message;

            await askVulaAboutTheCompany();
          }}
        >
          <div className="text-xs text-left w-full p-1 m-1">{message}</div>
          {/* send icon */}
          {loading ? (
            <Spinner />
          ) : (
            <img
              src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/send-alt-1.svg"
              className="w-[20px] h-[20px] mx-4 fill-white"
            />
          )}
        </button>
      </div>
    ));
  };

  return (
    <div className="flex flex-col justify-between h-full overflow-scroll no-scrollbar ">
      {/* a text box holding the users question that has already been asked to the chat bot */}
      <div className="mx-2">
        {messages.map(message => (
          <div
            key={message.text}
            className="flex flex-row items-end justify-start my-2"
          >
            {/* send icon */}

            <div className="border border-1 border-solid border-stone-200 text-sm text-left  w-full  rounded-md  px-3 py-2 ">
              <MarkdownText content={message.text} />
            </div>
            <img
              key={props.botIcon}
              // if the message was asked by the user, use the user's picture
              src={defineWriterImage(message.askedBy)}
              className={
                'w-[30px] h-[30px] fill-white border border-solid border-1 border-stone-300 rounded-full object-contain                ' +
                (message.askedBy !== 'user'
                  ? ' order-first mr-2'
                  : ' order-last ml-2')
              }
            />
          </div>
        ))}
      </div>
      <div>
        {/* a suggested question to ask */}
        {renderDefaultMessages()}

        {/* allow users to submit their own question */}
        <div className="flex flex-row items-center justify-center">
          <textarea
            ref={userAskInputRef}
            className="text-sm w-full  rounded-md border border-1  border-solid border-gray-300 p-2 m-2"
            placeholder="Type your question here..."
            // on enter press, send the message
            // do not use onKeyPress, use onKeyDown instead
            //  dont allow the user to type anymore if the message is loading
            disabled={loading}
            onKeyDown={async e => {
              setUserAskInput(userAskInputRef.current?.value || '');
              if (e.key === 'Enter') {
                await askVulaAboutTheCompany();
              }
            }}
          />
          <button
            className="border border-solid border-1 border-stone-300 text-white rounded-md px-4 py-2"
            onClick={async () => {
              if (!userAskInput.length && messages.length) {
                // clear the chat
                setMessages([]);
                // clear the input
                userAskInputRef.current!.value = '';
              } else {
                await askVulaAboutTheCompany();
              }
            }}
          >
            {/* send icon */}
            {loading ? (
              <Spinner />
            ) : // if the user has not typed anything, show clear chat icon
            !userAskInput.length && messages.length ? (
              <img
                src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/rubbish-bin.svg"
                className="w-[20px] h-[20px] fill-white"
              />
            ) : (
              <img
                src="https://vula-assets.s3.eu-west-2.amazonaws.com/icons/send-alt-1.svg"
                className="w-[20px] h-[20px] fill-white"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
