import React, { useState, useMemo, useContext } from 'react';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import { ConfigContext } from '../../contexts/ConfigContext';
import './CompanyTable.css';
import './CompanyTableParent.css';
import CompanyTableColumnHeader from './CompanyTableColumnHeader';
import { CompanyData, groupCompanies, sf_data } from '../../data/sf';

const stringIncludes = (str: string, searchTerm: string): boolean => {
  return str.toLowerCase().includes(searchTerm.toLowerCase());
};

interface CompanyTableProps {
  searchTerm: string;
  onRowSelect?: (rowData: CompanyData) => void;
  selectedRowId?: number | null;
}

export default function TableOfCompanies({
  onRowSelect,
  searchTerm,
  selectedRowId,
}: CompanyTableProps) {
  const { config } = useContext(ConfigContext);
  const [filters, setFilters] = useState<{
    // Filter by any key in the CompanyData object
    [key: string]: string | undefined;
  }>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  // this shouldnt return booleans, this should return the filtered data
  const applyFilters = (org: CompanyData) => {
    // first filter the children
    if (org.children) {
      org.children = org.children.reduce(
        (acc: CompanyData[], child: CompanyData) => {
          const filteredChild = applyFilters(child);
          if (filteredChild) {
            acc.push(filteredChild);
          }
          return acc;
        },
        [],
      );
      // if the children are not empty, return the org as we want to show it
      if (org.children.length > 0) {
        return org;
      }
    }

    // if the row is a group row,
    if (org.table_type === 'group_row') {
      // if the group row has children, return the group row
      if (org?.children && org?.children?.length > 0) {
        return org;
      }
    }

    // Check if the organization matches the general search term
    const matchesSearch =
      !searchTerm || // If there is no search term, return true
      [
        // Check if any of the values in the organization match the search term
        org.name,
        org.description,
        org.hq,
        org.type,
        org.verticals,
        ...(org?.impactAreas || []),
      ].some(
        value => typeof value === 'string' && stringIncludes(value, searchTerm),
      );

    //   check if the organization name partially matches the search term
    const matchesName = !filters.name || stringIncludes(org.name, filters.name);

    //  check if the organization description partially matches the search term
    const matchesDescription =
      !filters.description ||
      stringIncludes(org.description, filters.description);

    // check hq
    const matchesHQ = !filters.hq || stringIncludes(org?.hq || '', filters.hq);

    //   Check if the organization type matches the filter
    const matchesType =
      !filters.type || org.type?.toLowerCase() === filters.type.toLowerCase();

    // Check if the organization impact area matches the filter
    const matchesImpactArea =
      !filters.impactArea ||
      org?.impactAreas?.includes(filters.impactArea) ||
      false;

    // Return true if all filters pass
    if (
      matchesSearch &&
      matchesType &&
      matchesImpactArea &&
      matchesName &&
      matchesDescription &&
      matchesHQ
    ) {
      return org;
    }
  };

  const removeEmptyRows = (org: CompanyData): CompanyData | undefined => {
    // If the name is empty, return undefined
    if (!org.name) {
      return undefined;
    }

    // If there are children, recursively filter them
    if (org.children && org.children.length > 0) {
      const filteredChildren = org.children
        .map(removeEmptyRows)
        .filter((child): child is CompanyData => child !== undefined);

      // If there are filtered children, return the org with filtered children
      // Otherwise, return the org without children
      return filteredChildren.length > 0
        ? { ...org, children: filteredChildren }
        : { ...org, children: undefined };
    }

    // If there are no children, return the org as is
    return org;
  };

  // const warnOfDuplicateIds = (org: CompanyData[], idsSoFar: Set<number>) => {
  //   org.forEach(company => {
  //     if (idsSoFar.has(company.id)) {
  //       console.warn(`Duplicate id: ${company.id}`);
  //     }
  //     idsSoFar.add(company.id);
  //     if (company.children) {
  //       // also check children, and add their ids to the set
  //       warnOfDuplicateIds(company.children, idsSoFar);
  //     }

  //     return idsSoFar;
  //   });

  //   return idsSoFar;
  // };

  const allData = useMemo(() => {
    let table_data = sf_data;

    // Filter data based on the viewer (ie emerald needs filtering to only show emerald companies)
    if (config.features?.navigator?.hack_filter_on) {
      table_data =
        table_data.filter(
          org => org.id === config?.features?.navigator?.hack_filter_on,
        )[0]?.children || [];
    }

    // Filter out empty rows
    table_data = table_data.reduce((acc: CompanyData[], org: CompanyData) => {
      const filteredOrg = removeEmptyRows(org);
      if (filteredOrg) {
        acc.push(filteredOrg);
      }
      return acc;
    }, []);

    // warn if any ids are the same in the data (parents, children, children of children, etc)
    // console.log(warnOfDuplicateIds(table_data, new Set()));

    // for dev purposes
    // table_data = table_data.slice(0, 100);

    let grouped_data = groupCompanies(table_data as CompanyData[]);

    // Filter data based on the filters
    grouped_data = grouped_data.map(group => {
      // recursively filter children
      group.children = group?.children?.reduce(
        (acc: CompanyData[], child: CompanyData) => {
          const filteredChild = applyFilters(child);
          if (filteredChild) {
            acc.push(filteredChild);
          }
          return acc;
        },
        [],
      );

      return group; // Add this return statement
    });

    return grouped_data;
  }, [searchTerm, filters, config]);

  const handleExpand = (rowData: CompanyData) => {
    if (!rowData.children || rowData.children.length === 0) {
      onRowSelect && onRowSelect(rowData);
      return;
    }

    let keys = [...expandedRowKeys];
    const index = keys.indexOf(rowData.id);
    if (index === -1) {
      keys.push(rowData.id);
      if (rowData.table_type !== 'group_row') {
        onRowSelect && onRowSelect(rowData);
      }
    } else {
      keys.splice(index, 1);
    }
    setExpandedRowKeys(keys);
  };

  const handleFilterChange = (
    filterType: 'type' | 'impactArea' | 'description' | 'name' | 'hq',
    value: string | undefined,
  ) => {
    setFilters(prev => ({ ...prev, [filterType]: value }));
  };

  const getTypeColor = (type: string): string => {
    switch (type.toLowerCase()) {
      case 'investor':
        return 'bg-blue-500';
      case 'company':
        return 'bg-green-500';
      case 'network':
        return 'bg-yellow-500';
      case 'bank':
        return 'bg-purple-500';
      case 'support organisation':
        return 'bg-red-500';
      case 'onlender':
        return 'bg-indigo-500';
      case 'microfinance institution':
        return 'bg-pink-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getImpactColor = (impact_number: string): string => {
    // 1 to 15 always have a different color
    const number = parseInt(impact_number);
    switch (number) {
      case 1:
        return 'bg-green-500';
      case 2:
        return 'bg-blue-500';
      case 3:
        return 'bg-yellow-500';
      case 4:
        return 'bg-purple-500';
      case 5:
        return 'bg-red-500';
      case 6:
        return 'bg-indigo-500';
      case 7:
        return 'bg-pink-500';
      case 8:
        return 'bg-green-600';
      case 9:
        return 'bg-blue-600';
      case 10:
        return 'bg-yellow-600';
      case 11:
        return 'bg-purple-600';
      case 12:
        return 'bg-red-600';
      case 13:
        return 'bg-indigo-600';
      case 14:
        return 'bg-pink-600';
      case 15:
        return 'bg-green-700';
      default:
        return 'bg-gray-500';
    }
  };

  const rowClassName = (rowData: CompanyData) => {
    if (rowData?.id === selectedRowId) {
      return 'selected-row';
    }
    if (rowData?.table_type === 'group_row') {
      // class is stored in CompanyTable.css
      return 'group-row';
    }
    return '';
  };

  return (
    <div className="py-2 text-start text-sm ">
      <Table
        virtualized
        height={window.innerHeight - 100}
        data={allData}
        rowKey="id"
        expandedRowKeys={expandedRowKeys}
        onRowClick={handleExpand}
        isTree
        rowClassName={rowClassName}
      >
        <Column width={200} resizable fixed="left" treeCol>
          <HeaderCell>
            <CompanyTableColumnHeader
              title="Name"
              filterType="search"
              filterChange={value => handleFilterChange('name', value)}
            />
          </HeaderCell>
          <Cell dataKey="name" className="!bg-transparent">
            {(rowData: CompanyData) => {
              if (
                rowData?.children?.length ||
                rowData.table_type === 'group_row'
              ) {
                return (
                  <span className="text-xs sm:text-sm">
                    {rowData.name} ({rowData?.children?.length})
                  </span>
                );
              }

              return rowData.name;
            }}
          </Cell>
        </Column>

        <Column width={200} resizable>
          <HeaderCell>Logo</HeaderCell>
          <Cell dataKey="logoUrl" className="!bg-transparent">
            {(rowData: CompanyData) => {
              if (rowData.logoURL === '' || rowData.logoURL === undefined) {
                return null;
              }
              return (
                <div
                  className={
                    'flex justify-center  rounded-t-lg px-1 lazyload-image lazyload-image-background  ' +
                    (rowData.type === 'company' ? '  ' : ' ')
                  }
                >
                  <img
                    src={
                      rowData.logoURL
                        ? rowData.logoURL
                        : `https://via.placeholder.com/80x40.png?text=${rowData.name}`
                    }
                    className="drop-shadow-[0_0px_2px_rgba(0,0,0,.6)] "
                    alt={`${rowData.name} logo`}
                    style={{
                      height: '35px',
                      objectFit: 'contain',
                    }}
                    onError={e => {
                      // If image fails to load, replace with the name of the organization
                      e.currentTarget.src = `https://via.placeholder.com/80x40.png?text=${rowData.name}`;
                    }}
                  />
                </div>
              );
            }}
          </Cell>
        </Column>

        {/* if we are not on the `/search` page show a button that will take the user to that page */}
        {window.location.pathname !== '/search' ? (
          <Column width={100}>
            <HeaderCell>
              <b className="text-xs">More</b>
            </HeaderCell>
            <Cell className="!bg-transparent">
              {(rowData: CompanyData) => {
                if (rowData.table_type === 'group_row') {
                  return null;
                }
                return (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
                    onClick={() => {
                      window.location.href = '/search?s=' + rowData.name;
                    }}
                  >
                    Search
                  </button>
                );
              }}
            </Cell>
          </Column>
        ) : null}

        <Column width={500} resizable>
          <HeaderCell>
            <CompanyTableColumnHeader
              title="Description"
              filterType="search"
              filterChange={value => handleFilterChange('description', value)}
            />
          </HeaderCell>
          <Cell
            dataKey="description"
            className="!bg-transparent sm:text-sm text-xs"
          />
        </Column>

        <Column width={100} resizable>
          <HeaderCell>
            <CompanyTableColumnHeader
              title="HQ"
              filterType="search"
              filterChange={value => handleFilterChange('hq', value)}
            />
          </HeaderCell>
          <Cell dataKey="hq" className="!bg-transparent sm:text-sm text-xs" />
        </Column>

        <Column width={200} resizable>
          <HeaderCell>
            <select
              onChange={e => handleFilterChange('type', e.target.value)}
              className="font-bold rounded-lg w-full  sm:text-sm text-xs"
            >
              <option value="">Type</option>
              {[
                '3PL Provider',
                'AI and technology company',
                'Academic Institution',
                'Academic Publisher',
                'Academy',
                'Accelerator',
                'Accomodation Provider',
                'Actuarial Services Firm',
                'Ad-network & Affiliate Network',
                'Advertising Agency',
                'Advertising Platform',
                'Advertising and Communications Firm',
                'Advisory',
                'Advisory & Investment Firm',
                'Advisory Company',
                'Advisory Firm',
                'Advisory Services',
                'Advisory and Fund Management Firm',
                'Advisory and consultancy',
                'Advisory firm',
                'Advocacy organization',
                'Advocacy platform',
                'Agency',
                'Agri investment and advisory firm',
                'Agri-Business Advisory Support Corporation',
                'Agri-Fintech company',
                'Agri-Tech company',
                'Agri-fin-tech company',
                'Agri-fintech platform',
                'Agri-tech startup',
                'AgriFintech',
                'AgriTech startup',
                'Agribusiness',
                'Agribusiness Service Provider',
                'Agricultural Company',
                'Agricultural Cooperative',
                'Agricultural Investment Company',
                'Agriculture and food ecosystem builder',
                'Alliance',
                'Alternative Asset Manager',
                'Alternative lender',
                'Alumni Association',
                'Alumni Organization',
                'Analysis and Advisory Organization',
                'Animation Studio',
                'Applied research centre',
                'Art platform',
                'Artist',
                'Asset Management',
                'Asset Management Firm',
                'Asset Manager',
                'Association',
                'Automotive Company',
                'B Corp',
                'B Corporation',
                'B2B Manufacturer',
                'B2B Ordering Provider',
                'B2B Website',
                'Bank',
                'Banking',
                'Banking and financial services organization',
                'Banking institution',
                'Biopharma Partner',
                'Biotechnology Company',
                'Blog',
                'Boutique search firm',
                'Brand Communications Agency',
                'Brand Consultancy',
                'Brand Incubation',
                'Broker',
                'Brokerage',
                'Business Accelerator',
                'Business Advisory Firm',
                'Business Consultancy',
                'Business Consultant',
                'Business Development Firm',
                'Business Enabler',
                'Business Incubator',
                'Business Intelligence Company',
                'Business Law Firm',
                'Business Membership Organization',
                'Business Platform',
                'Business School',
                'Business Support Organization',
                'Business development and corporate finance advisory',
                'Café',
                'Catalytic Capital Facility',
                'Charitable Foundation',
                'Charitable foundation',
                'Charity',
                'Chartered Accountancy firm',
                'Church',
                'Clinical Psychologist',
                'Cloud Service Provider',
                'Co-operative',
                'Co-working and community space',
                'Co-working hub',
                'Coaching and Mentoring Organization',
                'Collaboration',
                'Collaboration platform',
                'Collaborative',
                'Collection agency',
                'Collective',
                'College',
                'Commercial Bank',
                'Commercial UAS service provider',
                'Commercial bank',
                'Commercial investment firm',
                'Communications Agency',
                'Communications agency',
                'Community',
                'Community Association Management Company',
                'Community Bank',
                'Community Organization',
                'Community Platform',
                'Community initiative',
                'Community organization',
                'Community project',
                'Community-focused yoga studio',
                'Company',
                'Conglomerate',
                'Construction Company',
                'Construction company',
                'Consultancy',
                'Consultancy Firm',
                'Consultancy and Investment Organization',
                'Consultancy and Training',
                'Consultancy and Training Organization',
                'Consultant',
                'Consulting',
                'Consulting Company',
                'Consulting Engineering Firm',
                'Consulting Firm',
                'Consulting Group',
                'Consulting Organization',
                'Consulting Services',
                'Consulting and Advisory Firm',
                'Consulting and Contracting',
                'Consulting and Management Firm',
                'Consulting and Software Development Company',
                'Consulting and investment firm',
                'Consulting and technology services company',
                'Consulting firm',
                'Consulting group',
                'Consumer Goods Company',
                'Consumer Insights Company',
                'Consumer Product Company',
                'Consumer goods company',
                'Cooperative',
                'Cooperative Bank',
                'Cooperative federation',
                'Corporate finance and advisory firm',
                'Corporation',
                'Coworking and Community Hub',
                'Creative Agency',
                'Creative Hub',
                'Creative Services Incubator',
                'Creative agency',
                'Creative international agency',
                'Credit Union',
                'Credit intermediary',
                'Crowdfunding Platform',
                'Crowdfunding platform',
                'Cultural Institute',
                'Custom fabrication studio',
                'Cybersecurity Consulting Firm',
                'Cybersecurity Firm',
                'Cybersecurity company',
                'Data Collection Company',
                'Data Solutions',
                'Data analytics and intelligence platform',
                'Data and Analytics Provider',
                'Data platform',
                'DeFi platform',
                'Decarbonisation Solutions Company',
                'Dental clinic',
                'Design Firm',
                'Design and Manufacturing',
                'Development Bank',
                'Development Finance Institution',
                'Development Finance Institution/ Multilateral Development Bank',
                'Development company',
                'Development financier',
                'Digital Agency',
                'Digital B2B Publisher',
                'Digital Credit Provider',
                'Digital Investment Platform',
                'Digital Marketing Agency',
                'Digital Media Platform',
                'Digital Payment Service',
                'Digital Platform',
                'Digital Product Architects',
                'Digital media company',
                'Digital platform',
                'Distributor',
                'Documentary Production House',
                'Documentary Project',
                'E-Logistics Company',
                'E-commerce',
                'E-commerce platform',
                'E-money solutions provider',
                'E.S.N (Entreprise de Services du numérique)',
                'ESG Support Organization',
                'Ecosystem Builder',
                'Ecosystem Support Organization',
                'EdTech',
                'EdTech Company',
                'EdTech startup',
                'Education',
                'Education Institute',
                'Education Institution',
                'Education Provider',
                'Education and Language Training Organization',
                'Educational Centre',
                'Educational Institution',
                'Electronics Manufacturing',
                'Email Marketing Agency',
                'Energy Generation Company',
                'Engineering Consulting Firm',
                'Engineering and environmental consultancy',
                'Enterprise Support Organization',
                'Enterprise support organization',
                'Entertainment Company',
                'Entrepreneur Support Organization',
                'Entrepreneur support organization',
                'Entrepreneurship Development Firm',
                'Entrepreneurship Support Organization',
                'Environmental Technology Company',
                'Error Handling Service',
                'Event Organizer',
                'Events Venue and Art Gallery',
                'Executive Search Consultancy',
                'Executive Search Firm',
                'Executive Search firm',
                'Export company',
                'Family Business',
                'Family Company',
                'Family Office',
                'Family foundation',
                'Fellowship',
                'Fellowship organization',
                'Film Post House',
                'Fin-comms consultancy',
                'FinTech',
                'Finance Platform',
                'Financial Advisor',
                'Financial Advisory',
                'Financial Content Community',
                'Financial Institution',
                'Financial Media',
                'Financial Organization',
                'Financial Service Provider',
                'Financial Services',
                'Financial Services Company',
                'Financial Services Firm',
                'Financial Services Organization',
                'Financial Technology',
                'Financial Technology Company',
                'Financial Technology Provider',
                'Financial Technology company',
                'Financial advisory & solutions provider',
                'Financial advisory firm',
                'Financial institution',
                'Financial organization',
                'Financial services firm',
                'Financial services organization',
                'Financial services provider',
                'Financial technology company',
                'Financier',
                'Financing company',
                'Financing platform',
                'Fintech',
                'Fintech Company',
                'Fintech platform',
                'Food company',
                'For-profit Social Enterprise',
                'For-profit social enterprise',
                'Foundation',
                'Franchisor',
                'Freelance Marketplace',
                'Freelancing platform',
                'Freight Brokerage',
                'Freight Forwarder',
                'Fund',
                'Fund Manager',
                'Fund and Advisory Service',
                'Fund management and advisory firm',
                'Funding Platform',
                'Funding and support organization',
                'Funding initiative',
                'Funding organization',
                'Game Development Company',
                'Game Publishing Company',
                'Global Development Institution',
                'Global Initiative',
                'Global Law Firm',
                'Global Management Consulting Firm',
                'Global field-building initiative',
                'Global product development partner',
                'Government Body',
                'Government body',
                'Graduate School',
                'Grant-making foundation',
                'Grantmaking foundation',
                "Groupe d'investissement",
                'Health Services Provider',
                'Health Technology Company',
                'Health care provider',
                'Healthcare Enterprise',
                'Healthcare Provider',
                'Healthcare Service Provider',
                'Higher Education Institution',
                'Higher education institution',
                'Holding Company',
                'Hospitality',
                'Hospitality and Education Organization',
                'Hosting Control Panel',
                'Hosting company',
                'Hotel',
                'Hotel and Resort',
                'IT Service Company',
                'IT Service Provider',
                'IT Services Provider',
                'IT Solutions Company',
                'IT Support Organization',
                'IT Support organization',
                'Immigration Consultancy',
                'Impact Agency',
                'Impact Asset Manager',
                'Impact Finance Specialist',
                'Impact Fund Manager',
                'Impact Investing Firm',
                'Impact Investing Platform',
                'Impact Investment Advisory and Asset Management firm',
                'Impact Investment Company',
                'Impact Investment Firm',
                'Impact Investment Fund',
                'Impact Investment Fund Manager',
                'Impact Investment Group',
                'Impact Investment Hub',
                'Impact Investment Management Company',
                'Impact Investment Management Firm',
                'Impact Investment Organization',
                'Impact Investment Platform',
                'Impact Investment and Accelerator',
                'Impact Investment and Advisory Firm',
                'Impact Investment firm',
                'Impact Investor',
                'Impact Management Platform',
                'Impact Network',
                'Impact Platform',
                'Impact and Innovation Company',
                'Impact consultancy',
                'Impact finance firm',
                'Impact fund',
                'Impact fund manager',
                'Impact innovation firm',
                'Impact investing initiative',
                'Impact investment management firm',
                'Impact organization',
                'Impact-first investor',
                'Importer',
                'Incoming Tour Operator',
                'Incubator',
                'Incubator and Fund Manager',
                'Incubator and Impact Investor',
                'Incubator/Accelerator',
                'Independent Abortion Provider',
                'Independent College',
                'Independent Financial Adviser',
                'Independent financial services company',
                'Industrial Systems Technology',
                'Industry Association',
                'Industry Body',
                'Information Services',
                'Infrastructure services partner',
                'Innovation Accelerator',
                'Innovation Agency',
                'Innovation Center',
                'Innovation Hub',
                'Innovation Organization',
                'Innovation ecosystem booster',
                'Institute of Higher Education',
                'Insurance',
                'Insurance Brokerage',
                'Insurance Company',
                'Insurance Intermediary',
                'Insurance Provider',
                'Insurance company',
                'Integrated Health and Human Services Network',
                'Integrated Poultry Producer',
                'Integrated Product Development Agency',
                'Intergovernmental Organisation',
                'Intergovernmental Organization',
                'Intergovernmental organization',
                'International Company',
                'International Development Company',
                'International Development Consultancy',
                'International Development Organization',
                'International Financial Institution',
                'International Initiative',
                'International Legal Practice',
                'International Non-Governmental Organization (INGO)',
                'International Organisation',
                'International Organization',
                'International financial institution',
                'International organization',
                'Internet Service Provider',
                'Investigative Research Firm',
                'Investment',
                'Investment Adviser',
                'Investment Advisory',
                'Investment Advisory Company',
                'Investment Advisory Firm',
                'Investment Advisory and Asset Management',
                'Investment Bank',
                'Investment Bank and Manager',
                'Investment Banking Boutique',
                'Investment Company',
                'Investment Firm',
                'Investment Fund',
                'Investment Fund Manager',
                'Investment Management',
                'Investment Management Company',
                'Investment Management and Advisory Firm',
                'Investment Manager',
                'Investment Partnership',
                'Investment advisory and private equity firm',
                'Investment advisory firm',
                'Investment and Advisory Firm',
                'Investment and Business Support Company',
                'Investment and Consulting Organization',
                'Investment and advocacy firm',
                'Investment bank',
                'Investment banking and advisory firm',
                'Investment company',
                'Investment consultant and advisor',
                'Investment firm',
                'Investment fund',
                'Investment fund manager',
                'Investment holding company',
                'Investment management',
                'Investment manager',
                'Investor',
                'Investor Network',
                'Investor and Developer',
                'Investor and accelerator',
                'Investors',
                'Job Portal',
                'Joint Venture',
                'Knowledge infrastructure organization',
                'Law Firm',
                'Law firm',
                'Leadership Training Organization',
                'Learning Platform',
                'Legal Services',
                'Logistics company',
                'Logistics service provider',
                'Luxury Retailer',
                'M&A Advisory Firm',
                'Magento e-commerce development agency',
                'Management Consulting',
                'Management Consulting Firm',
                'Management Development Institute',
                'Management consulting & financial advisory firm',
                'Manufacturer',
                'Manufacturing',
                'Market Research Provider',
                'Market Research and Consulting Firm',
                'Marketing Agency',
                'Marketing Services Group',
                'Marketplace',
                'Media',
                'Media & Technology Firm',
                'Media Company',
                'Media Group',
                'Media Organization',
                'Media Outlet',
                'Media company',
                'Media/Publication',
                'Medical Technology Company',
                'Membership Organization',
                'Membership organisation',
                'Membership organization',
                'Mentorship Organization',
                'Micro-consulting agency',
                'Microfinance Bank',
                'Microfinance Institution',
                'Microfinance institution',
                'Microfinance operator-investor',
                'Microfinance organization',
                'Mining Company',
                'Mortgage Bank',
                'Movement',
                'Multi-product, Multi-channel organization',
                'Multi-stakeholder initiative',
                'Multi-stakeholder platform',
                'Multinational Corporation',
                'NBFC',
                'NGO',
                'NPO',
                'National nonprofit',
                'Netwerk',
                'Network',
                'Networking organization',
                'News Journal',
                'News Portal',
                'Non-Profit Foundation',
                'Non-Profit Organization',
                'Non-bank financial services',
                'Non-banking financial institution',
                'Non-profit',
                'Non-profit Organization',
                'Non-profit association',
                'Non-profit corporation',
                'Non-profit educational foundation',
                'Non-profit foundation',
                'Non-profit initiative',
                'Non-profit organization',
                'Non-profit program',
                'Non-profit research organization',
                'Non-profit social enterprise',
                'Nonprofit',
                'Nonprofit Foundation',
                'Nonprofit Healthcare Organization',
                'Nonprofit Impact Investing Firm',
                'Nonprofit Insurance Brokerage',
                'Nonprofit Organisation',
                'Nonprofit Organization',
                'Nonprofit investment firm',
                'Nonprofit organization',
                'Nonprofit social enterprise',
                'Nonprofit trade association',
                'Not Applicable',
                'Not Available',
                'Not Provided',
                'Not Specified',
                'Not specified',
                'Not-for-Profit Organization',
                'Not-for-Profit Social Enterprise',
                'Not-for-profit',
                'Not-for-profit organization',
                'Not-for-profit social enterprise',
                'Onlender',
                'Online Advertising Platform',
                'Online Betting Platform',
                'Online Content Creation',
                'Online Course Provider',
                'Online Design School',
                'Online Game',
                'Online Gaming Platform',
                'Online Investment Platform',
                'Online Lending Platform',
                'Online Marketplace',
                'Online Media',
                'Online Platform',
                'Online Resource Hub',
                'Online Service',
                'Online educational platform',
                'Online gambling platform',
                'Online game developer',
                'Online gaming platform',
                'Online platform',
                'Online portal',
                'Online quiz platform',
                'Online retail',
                'Open-source project',
                'Organisation',
                'Organisational Tool',
                'Organization',
                'Other',
                'PR Agency',
                'Pan-African Support Organization',
                'Payroll Administrator',
                'Philanthropic Advisory Firm',
                'Philanthropic Foundation',
                'Philanthropic Fund',
                'Philanthropic Organization',
                'Philanthropic Trust',
                'Philanthropic organization',
                'Philanthropy',
                'Philanthropy Support Organization',
                'Photography studio',
                'Platform',
                'Platform Company',
                'Policy Research Institute',
                'Portfolio Management Platform',
                'Private Bank',
                'Private Company',
                'Private Equity Firm',
                'Private Equity Fund',
                'Private Equity Fund Manager',
                'Private Equity Investor',
                'Private Equity and Credit Fund Manager',
                'Private Equity firm',
                'Private Investment Firm',
                'Private Investment Fund',
                'Private Investment Holding Company',
                'Private Power Company',
                'Private Sector Development Programme',
                'Private capital advisor',
                'Private company',
                'Private equity firm',
                'Private equity fund manager',
                'Private funder',
                'Private grantmaking foundation',
                'Private real estate equity firm',
                'Private-sector initiative',
                'Production company',
                'Professional Association',
                'Professional Membership Organization',
                'Professional Organization',
                'Professional Services Firm',
                'Professional services firm',
                'Professional services network',
                'Professional training organization',
                'Program',
                'Project',
                'Project finance and advisory firm',
                'Promotional Product Distributor',
                'Property Development and Management',
                'Public Affairs and Strategic Communications Agency',
                'Public Benefit Corporation',
                'Public Company',
                'Public Housing Authority',
                'Public Investment Fund',
                'Public Limited Company',
                'Public Open Market Distributor',
                'Public Relations Agency',
                'Public School District',
                'Public Service Broadcaster',
                'Public Telecommunications Operator',
                'Public body',
                'Public economic entity',
                'Public limited company',
                'Publisher',
                'Publishing',
                'Publishing House',
                'Publishing organization',
                'Real Estate Advisors',
                'Real Estate Development',
                'Real Estate Investment Advisor',
                'Real Estate Services and Technology Platform',
                'Recruiting platform',
                'Recruitment Agency',
                'Recruitment Consultancy',
                'Recruitment and Immigration Consultancy',
                'Red',
                'Regional Organization',
                'Regional development finance organization',
                'Regulatory Authority',
                'Rehab Center',
                'Religious Organization',
                'Renewable Energy Developer',
                'Research & Development Lab',
                'Research Center',
                'Research Centre',
                'Research Institute',
                'Research Institution',
                'Research Partnership',
                'Research Think Tank',
                'Research and Advisory Firm',
                'Research and Advocacy Organization',
                'Research and Analysis Organization',
                'Research project',
                'Resource Organization',
                'Retail',
                'Retail Distribution',
                'Retail corporation',
                'Retailer',
                'Rural Bank',
                'SME Support Organization',
                'SPAC Sponsor',
                'SaaS',
                'SaaS Support organization',
                'SaaS provider',
                'SaaS-based AgTech Company',
                'Sales Agency',
                'Savings and Loans Institution',
                'School',
                'School District',
                'Security Service',
                'Security Service Provider',
                'Security service',
                'Seed Distribution Company',
                'Service Provider',
                'Service provider',
                'Shipping carrier',
                'SME',
                'SME Support organization',
                'Small Business Enterprise (SBE)',
                'Social Change Funder',
                'Social Enterprise',
                'Social Enterprise and Charity',
                'Social Impact Business',
                'Social Impact Company',
                'Social Impact Consulting',
                'Social Impact Fund',
                'Social Impact Investment Fund',
                'Social Impact Measurement Organization',
                'Social Impact Venture Builder',
                'Social Innovation Design Studio',
                'Social Investment Wholesaler',
                'Social Media Platform',
                'Social Research Company',
                'Social Venture',
                'Social enterprise',
                'Social impact network',
                'Social impact organization',
                'Social investment intermediary',
                'Social networking app',
                'Software',
                'Software Company',
                'Software Development Company',
                'Software Provider',
                'Software Solution',
                'Software and Services Provider',
                'Software company',
                'Software-as-a-Service',
                'Specialized Agency',
                'Spiritual movement',
                'Sports organization',
                'Standards-setting organization',
                'Startup',
                'Startup Accelerator',
                'Startup Incubator',
                'Strategic Advisory Firm',
                'Student Organization',
                'Superyacht Agency',
                'Supplier',
                'Support organization',
                'Sustainability Consulting',
                'TPA',
                'Talent Search Platform',
                'Tax & Business Consultants',
                'Tech Company',
                'Tech Hub',
                'Tech School',
                'Tech Training Organization',
                'Tech company',
                'Tech platform',
                'Technology',
                'Technology Business Incubator',
                'Technology Company',
                'Technology Consulting Firm',
                'Technology Platform',
                'Technology Provider',
                'Technology Solutions',
                'Technology Solutions Provider',
                'Technology and Data Platform',
                'Technology company',
                'Technology enabler',
                'Technology partner',
                'Technology platform',
                'Technology provider',
                'Technology solutions provider',
                'Telecommunications',
                'Telecommunications Company',
                'Theatre',
                'Think Tank',
                'Think Tank and Consultancy',
                'Think tank',
                'Think-and-Do Tank',
                'Think-tank',
                'Trading company',
                'Training Organization',
                'Training and Consultancy organization',
                'Training organization',
                'Transaction advisory firm',
                'Transportation Company',
                'Travel Management Company',
                'Travel Organization',
                'Travel agency',
                'UN Organization',
                'United Nations Agency',
                'Universal Bank',
                'University',
                'University Research Institute',
                'Unknown',
                'VC Fund',
                'VC fund',
                'Venture Builder',
                'Venture Capital',
                'Venture Capital Firm',
                'Venture Capital Fund',
                'Venture Capital Investment Manager',
                'Venture Capital Investor',
                'Venture Capital and Advisory Firm',
                'Venture Capital firm',
                'Venture Capital fund',
                'Venture Debt Fund and Advisory Services',
                'Venture Development Company',
                'Venture Fund',
                'Venture Holding Company',
                'Venture Platform',
                'Venture Studio',
                'Venture Studio and Startup Accelerator',
                'Venture and Innovation Platform',
                'Venture capital',
                'Venture capital firm',
                'Venture capital fund',
                'Venture philanthropy firm',
                'Venture platform',
                'Virtual Assistant Firm',
                'Waste Management Company',
                'Wealth Management Firm',
                'Wealth management company',
                'Wealth management firm',
                'Web Design and Development Company',
                'Web Development Platform',
                'Web Hosting Service',
                'Web Hosting provider',
                'Website Error',
                'Web制作会社',
                'Wholesale Retailer',
                'Wholesale Trade Organization',
                'Winery',
                'سازمان حمایت از SME',
                'منظمة',
                '婚活支援組織',
              ].map(type => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </HeaderCell>
          <Cell className="!bg-transparent">
            {(rowData: CompanyData) =>
              rowData.type ? (
                <span
                  className={`${getTypeColor(
                    rowData.type,
                  )} text-white  sm:text-sm text-xs py-1 px-2 rounded-full`}
                >
                  {rowData.type}
                </span>
              ) : null
            }
          </Cell>
        </Column>
        <Column width={200} resizable>
          <HeaderCell>
            <select
              onChange={e => handleFilterChange('impactArea', e.target.value)}
              className="font-bold rounded-lg w-full  sm:text-sm text-xs"
            >
              <option value="">Impact area</option>
              {[
                '1 Increased mobilisation of capital (local)',
                '2 Increased mobilisation of capital (international)',
                '3 Viable model proven',
                '4 Viable model replicated',
                '5 Increased profits for rural-impacting MSMEs',
                '6 Increased power and voice (rural stakeholders)',
                '7 Increased local power and voice (all intermediaries)',
                '8 Improved gender equality',
                '9 Changes to policy, law, regulation that benefits rural',
                '10 Increased relationships and connections / improved trust',
                '11 Increased African expertise',
                '12 Improved livelihoods - Increased employment',
                '13 Improved livelihoods - Increased income for smallholder farmers (customers, suppliers)',
                '14 Improved livelihoods - Increased access to finance for people living in poverty in rural areas',
                '15 Improved livelihoods - Improved resilience (ability to withstand shocks, incl food security)',
              ].map(area => (
                <option value={area}>{area}</option>
              ))}
            </select>
          </HeaderCell>
          <Cell className="!bg-transparent">
            {(rowData: CompanyData) =>
              rowData.impactAreas
                ? rowData.impactAreas.map(area => (
                    <span
                      className={`${getImpactColor(
                        area.split(' ')[0],
                      )} text-white py-1 px-2 mr-1 rounded-full  sm:text-sm text-xs`}
                    >
                      {area.split(' ')[0]}
                    </span>
                  ))
                : null
            }
          </Cell>
        </Column>
        <Column width={100}>
          <HeaderCell>
            <b className="text-xs">Website</b>
          </HeaderCell>
          <Cell className="!bg-transparent">
            {(rowData: CompanyData) => (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded  sm:text-sm text-xs"
                onClick={e => {
                  e.stopPropagation();
                  window.open(rowData.website, '_blank');
                }}
              >
                Visit
              </button>
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  );
}
