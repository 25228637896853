import React from 'react';
import Chevron from '../Icons/Chevron';
import SocialMediaIcons from './SocialMediaIcons';
import ExpandableDetail from './ExpandableDetail';
import { sf_data, CompanyData } from '../../data/sf';
import DetailWindowFlag from './DetailWindowFlag';
import AlertIcon from '../Icons/AlertIcon';

interface Props {
  selectedRowId: number | undefined;
  closeModal: () => void;
}
export default function DetailWindow(props: Props) {
  const { selectedRowId } = props;
  const [selectedRow, setSelectedRow] = React.useState<CompanyData | undefined>(
    undefined,
  );
  const [windowInnerHeight, setWindowInnerHeight] = React.useState(
    window.innerHeight,
  );

  // when user rotates phone or changes the size of the window
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowInnerHeight(window.innerHeight);
    });
  }, []);

  React.useEffect(() => {
    if (selectedRowId) {
      //  flatten all children from their parents and search for the id
      const allData = sf_data as CompanyData[];
      const flattenedData = allData.reduce((acc: CompanyData[], item) => {
        acc.push(item);
        if (item.children) {
          acc.push(...item.children);
        }
        return acc;
      }, [] as CompanyData[]);
      const selected = flattenedData.find(item => item.id === selectedRowId);
      setSelectedRow(selected);
    }
  }, [selectedRowId]);

  return (
    <div className=" h-full  rounded-xl sm:rounded-3xl border-solid border-stone-200 text-white text-left shadow bg-center bg-[url(https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp)]">
      <div className="animate fade-in animate-fade-in">
        {/* header */}

        <div className="p-4 flex justify-between items-center">
          <button
            className="hover:bg-stone-600 transition-all duration-500 rounded-lg p-1 w-fit"
            onClick={props.closeModal}
          >
            <Chevron color="white" />
          </button>
          <DetailWindowFlag selectedRow={selectedRow} />
        </div>

        {selectedRow ? ( // check the type and render all fields of type
          // must be child
          <div
            className="px-1 overflow-y-scroll"
            style={{
              height: windowInnerHeight - 100,
            }}
          >
            {/* logo and overview */}
            <div className="flex flex-col flex-1 justify-start    text-stone-100 rounded-lg ">
              <div className="text-xs px-3 py-1 rounded-full w-fit bg-stone-700 text-white  my-1  ">
                {selectedRow.type}
              </div>
              <div className=" rounded-lg overflow-hidden flex space-y-8 flex-1 flex-col justify-between h-full">
                <img
                  src={selectedRow.logoURL}
                  className="drop-shadow-xl  rounded-lg overflow-hidden p-4 object-contain"
                  alt={`${selectedRow.name} logo`}
                  onError={e => {
                    // If image fails to load, replace with the name of the organization
                    e.currentTarget.src = `https://via.placeholder.com/80x40.png?text=${selectedRow.name}`;
                  }}
                />
              </div>

              <div className="text-left rounded-xl bg-stone-100 shadow-lg w-full p-4 text-stone-800">
                <h1 className="text-xl font-bold pt-12">{selectedRow.name}</h1>

                <SocialMediaIcons
                  name={selectedRow.name}
                  linkedIn={selectedRow.linkedinURL}
                  website={selectedRow.website}
                  onepageId={selectedRow.onepageId}
                  crunchbaseURL={selectedRow.crunchbaseURL}
                />
                <div className="text-sm">HQ: {selectedRow.hq}</div>
                <div className="text-sm">
                  {selectedRow?.relationship
                    ? 'Relationship: ' + selectedRow.relationship
                    : ''}
                </div>
              </div>
            </div>
            {selectedRow.description ? (
              <div className="text-sm p-4 mt-2 rounded-lg bg-stone-600 bg-opacity-50">
                {selectedRow.description}
              </div>
            ) : null}

            {/* if an error show a little warning */}
            {selectedRow.error ? (
              <div className="text-sm p-4 my-4 rounded-lg bg-stone-600 bg-opacity-50 flex items-center ">
                <AlertIcon color="white" />{' '}
                <div className="pl-4">{selectedRow.error}</div>
              </div>
            ) : null}

            {/* impact areas */}
            {selectedRow?.impactAreas?.length ? (
              <ExpandableDetail
                title="Impact Areas"
                cards={selectedRow.impactAreas.map(impact => ({
                  title: impact,
                }))}
              />
            ) : null}

            {selectedRow?.countriesOfOperation?.length ? (
              <ExpandableDetail
                title="Countries of Operation"
                cards={selectedRow.countriesOfOperation.map(c => ({
                  title: c,
                }))}
              />
            ) : null}
            {selectedRow?.verticals?.length ? (
              <ExpandableDetail
                title="Verticals"
                cards={selectedRow.verticals.split(',').map(c => ({
                  title: c,
                }))}
              />
            ) : null}

            {/* deals */}
            <ExpandableDetail
              title="Deals"
              cards={selectedRow.deals.map(deal => ({
                title: deal.name + ' : ' + deal.status,
                body: deal?.dealSummary || undefined,
                url:
                  'https://app.onepagecrm.com/pipeline/kanban/deals/' +
                  deal.dealId,
              }))}
            />
            {/* attachments */}
            <ExpandableDetail
              title="Attachments"
              cards={selectedRow.attachments.map(attachment => ({
                title: attachment.filename,
                url: attachment.url,
              }))}
            />
            {/* NOTES */}
            <ExpandableDetail
              title="Notes"
              cards={selectedRow.notes.map(note => ({
                title: note.author,
                body: note.text,
                date: note.date,
              }))}
            />
            {selectedRow?.contacts?.length ? (
              <ExpandableDetail
                title="Contacts"
                cards={selectedRow.contacts.map(contact => ({
                  title: contact.name || '',
                  body: contact.jobTitle + ' : ' + contact.linkedinURL,
                  url: contact.linkedinURL
                    ? contact.linkedinURL
                    : 'https://app.onepagecrm.com/show_contact/' +
                      contact.contactId,
                }))}
              />
            ) : null}

            {/* children */}
            {selectedRow.children && selectedRow.children.length ? (
              <ExpandableDetail
                title="Related Companies"
                cards={selectedRow.children.map(child => ({
                  title: child.name,
                  body: child.description,
                  img: child.logoURL,
                }))}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
