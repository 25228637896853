import React from 'react';
import ArrowIcon from '../Icons/ArrowIcon';
import VulaLogo from '../utils/VulaLogo';
import { ConfigContext } from '../../contexts/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';
import HomeIcon from '../Icons/HomeIcon';

interface NavigatorHeaderProps {
  searchText?: string;
  setSearchText?: (searchText: string) => void;
}

export default function NavigatorHeader({
  searchText,
  setSearchText,
}: NavigatorHeaderProps) {
  const navigate = useNavigate();
  const { config } = React.useContext(ConfigContext);

  return (
    <div className="p-1 sm:p-4  w-full m-auto flex justify-between ">
      {/* back to home */}
      <button
        className=" flex justify-center flex-row items-center px-4  bg-white rounded-full p-2 px-4 shadow"
        onClick={() => navigate(routes.home)}
      >
        <HomeIcon />
        <div className="hidden sm:block px-2 font-extrabold ">Home</div>
      </button>

      {/* search bar */}
      {setSearchText && (
        <div
          className={
            'max-w-[800px] w-full rounded-full  bg-white p-3 px-8 flex items-center  drop-shadow-sm border border-stone-200 border-solid border-1'
          }
        >
          <input
            type="text"
            placeholder="Search for names, geographies, verticals and more..."
            className="w-full bg-transparent outline-none"
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
          <ArrowIcon pointingDirection="right" fill={'black'} />
        </div>
      )}

      {/* ensure center search bar */}
      {config?.features?.navigator?.small_foundation_labs ? (
        <button
          className="flex items-center"
          onClick={() => navigate(routes.temp_sf_labs)}
        >
          {/* a special pill shaped div with our logo and the words labs */}
          <div className="hidden sm:flex shadow items-center justify-center hover:bg-stone-100 bg-white rounded-full p-2 px-4">
            <VulaLogo size="xs" />
            <div className="text-stone-900 font-extrabold px-2">Labs</div>
          </div>
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
}
